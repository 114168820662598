<template>
  <v-container>
    <v-row justify="end" class="mt-3">
      <v-col md="2">
        <v-btn color="success" small @click="create"
          >{{ $t("createNew") }} <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="10">
        <h3 class="mb-2">{{ $t("initiate.Shift") }}</h3>
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="items"
          style="border: 1px solid #999; cursor: pointer"
          hide-default-footer
          @click:row="navigate"
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td style="width: 80px">
                <v-icon color="grey" @click="navigate(item)">mdi-pencil</v-icon>
              </td>
              <td style="width: 80px">
                <v-icon color="red" @click="deleteitem(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [{ text: "name", value: "name" }],
      items: [],
      loading: true,
      delete_dialog: false,
      deleteItemId: null,
      confirm_delete: false,
    };
  },
  methods: {
    navigate(item) {
      this.$router.push(`/shifts/${item.id}`);
    },
    create() {
      this.$router.push("/shifts/CreateNewShift");
    },
    deleteitem(item) {
      this.deleteItemId = item.id;
      this.delete_dialog = true;
    },
  },
  watch: {
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/hr/shift/${this.deleteItemId}`).then((res) => {
          this.items = this.items.filter(
            (item) => item.id !== this.deleteItemId
          );
          if (res.status == 200) {
            this.$Notifications(
              "تم حذف وردية بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
          this.delete_dialog = false;
          this.confirm_delete = false;
        });
      }
    },
  },
  async created() {
    await axios.get("hr/shift").then((res) => {
      this.items = res.data.shifts;
      this.loading = false;
    });
  },
};
</script>