<template>
  <v-container>
    <v-row justify="end" class="mt-2">
      <v-col md="2">
        <v-btn small color="success" @click="createContract"
          ><v-icon>mdi-plus</v-icon>{{ $t("create") }}Create</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="10">
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="contracts"
          item-key="id"
          hide-default-footer
          style="border: 1px solid #ccc; border-radius: 4px; cursor: pointer"
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.type }}</td>
              <td>
                <v-icon
                  color="grey"
                  @click="Navigate(item)"
                  style="cursor: pointer"
                  >mdi-pencil</v-icon
                >
              </td>
              <td>
                <v-icon
                  color="red"
                  style="cursor: pointer"
                  @click="DeleteContract(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      headers: [
        { text: "name", value: "name" },
        { text: "النوع", value: "type" },
      ],
      contracts: [],
      select: ["أسبوعي", "شهري"],
      loading: true,
      delete_dialog: false,
      confirm_delete: false,
      deleteItemId: null,
      contractsType: [],
    };
  },

  methods: {
    Navigate(item) {
      this.$router.push(`/work_contract/${item.id}`);
    },
    createContract() {
      this.$router.push("/work_contract/Create_new_contract");
    },
    DeleteContract(item) {
      this.deleteItemId = item.id;
      this.delete_dialog = true;
    },
  },
  watch: {
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/hr/contract/${this.deleteItemId}`).then(() => {
          this.contracts = this.contracts.filter(
            (contract) => contract.id !== this.deleteItemId
          );
          this.delete_dialog = false;
          this.confirm_delete = false;
        });
      }
    },
  },
  async created() {
    await axios.get("/hr/contract").then((res) => {
      this.contracts = res.data.contracts;
      for (let i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].type == "weekly") {
          this.contracts[i].type = "أسبوعي";
        }
      }
      this.loading = false;
    });
  },
};
</script>