<template>
  <v-container>
    <v-row class="px-5 justify-center">
      <v-col align="center">
        <v-card>
          <v-tabs :dark="$store.state.isDarkMode" background-color="var(--bg-color--background)" align-tabs="center" v-model="Activetab">
            <v-tab v-for="tab in tabs" :key="tab.name" >{{
              $t(`${tab.name}`)
            }}</v-tab>
          </v-tabs>
        </v-card>
        <v-window v-for="(tab, index) in tabs" :key="tab.name" @click="Activetab = index">
          <v-window v-if="index == Activetab">
            <component :is="tab.component"></component>
          </v-window>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Shift from "./components/ShiftsView.vue";
import SalaryRules from "./components/SalaryRules.vue";
import SalaryVariants from "./components/SalarayVariants.vue";
import Vacation from "./components/VacationsCo.vue";
import EmployeeGroupes from "./components/EmployeeGroupes.vue";
import FormalDayOff from "./components/FormalDayOff.vue";
import WorkContract from "./components/WorkContract.vue";
import Custidy from "./components/Custidy.vue";
import Debt from "./components/SalaryLoan.vue";
export default {
  data() {
    return {
      Activetab: 0,
      tabs: [
        { name: "initiate.tabs.shifts", component: Shift },
        { name: "initiate.tabs.salaryRules", component: SalaryRules },
        { name: "initiate.tabs.salaryVariants", component: SalaryVariants },
        { name: "initiate.tabs.hourlyLeaves", component: Vacation },
        { name: "initiate.tabs.employeeGroupes", component: EmployeeGroupes },
        { name: "initiate.tabs.FormalDayOff", component: FormalDayOff },
        { name: "initiate.tabs.custdy", component: Custidy },
        { name: "initiate.tabs.Debt", component: Debt },
        { name: "initiate.tabs.workContract", component: WorkContract },
      ],
    };
  },
  watch: {
    "Activetab": function () {
      this.$store.commit("SET_ACTABHRIN", this.Activetab);
      localStorage.setItem('acTapInHr', this.Activetab);
    },
  },
  mounted() {
  this.$refs.tabs.activeTab = this.Activetab;
},
  methods: {
    show() {},
  },
  created(){
    console.log(this.$store.state.acTapHrIn);
    this.Activetab = this.$store.state.acTapHrIn;
    console.log(this.Activetab);
  },
};
</script>