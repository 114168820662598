<template>
  <v-container>
    <v-row justify="end" class="mt-3">
      <v-col md="2">
        <v-btn color="success" small @click="CreateLoan"
          >{{ $t("createNew") }} <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="10">
        <h3 class="mb-2">السلف</h3>
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="items"
          :loading="loading"
          style="border: 1px solid #999"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.payments_number }}</td>
              <td>{{ item.account_name }}</td>
              <td style="width: 5%">
                <v-icon
                  color="blue"
                  style="cursor: pointer"
                  @click="EditLoan(item)"
                  >mdi-pencil</v-icon
                >
              </td>
              <td style="width: 5%">
                <v-icon
                  color="red"
                  style="cursor: pointer"
                  @click="DeleteLoan(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center"> </v-row>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import i18n from "../../../../../../i18n";
export default {
  created() {
    this.initiate();
  },
  data() {
    return {
      headers: [
        { text: i18n.t('name'), value: "name" },
        { text: "عدد الدفعات", value: "payments_number" },
        { text: "اسم الحساب", value: "account_name" },{},{},
      ],
      items: [],
      loading: true,
      delete_dialog: false,
      confirm_delete: false,
    };
  },
  methods: {
    async initiate() {
      await axios.get("/hr/loan/type").then((res) => {
        this.items = res.data.loan_types;
        this.loading = false;
        console.log(res);
      });
    },
    EditLoan(name){
      this.$router.push(`SalaryLoan/${name.id}`);
    },
    CreateLoan() {
      this.$router.push(`SalaryLoan/CreateNewSalaryLoan`);
    },
    DeleteLoan(item) {
      axios.delete(`/hr/loan/type/${item.id}`).then((res) => {
        if (res.status == 200) {
          this.$Notifications(
            "تم حذف سلفة بنجاح",
            { rtl: true, timeout: 2500 },
            "success"
          );
          this.initiate();
        }
      });
    },
  },
};
</script>