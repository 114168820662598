<template>
  <v-container>
    <v-row justify="end" class="mt-3">
      <v-col md="2">
        <v-btn color="success" small @click="CreateRule"
          >{{ $t("createNew") }} <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="10">
        <h3 class="mb-2">{{ $t("initiate.SalaryRules") }}</h3>
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="items"
          :loading="loading"
          style="border: 1px solid #999"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.rule_type }}</td>
              <td>{{ item.value }}</td>
              <td style="width: 5%">
                <v-icon
                  color="grey"
                  @click="navigate(item)"
                  style="cursor: pointer"
                  >mdi-pencil</v-icon
                >
              </td>
              <td style="width: 5%">
                <v-icon
                  color="red"
                  style="cursor: pointer"
                  @click="DeleteContract(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center"> </v-row>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import i18n from '@/i18n';

export default {
  data() {
    return {
      headers: [
        { text: i18n.t('name'), value: "name" },
        { text: i18n.t('hr.employee.rules.type'), value: "rule_type" },
        { text: i18n.t('hr.employee.rules.value'), value: "value" },
      ],
      items: [],
      loading: true,
      delete_dialog: false,
      confirm_delete: false,
    };
  },
  methods: {
    
    navigate(name) {
      this.$router.push(`/SalaryRules/${name.id}`);
    },
    CreateRule() {
      this.$router.push("/SalaryRules/CreateNewRule");
    },
    DeleteContract(item) {
      this.deleteItemId = item.id;
      this.delete_dialog = true;
    },
  },
  watch: {
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/hr/rule/${this.deleteItemId}`).then(() => {
          this.items = this.items.filter(
            (item) => item.id !== this.deleteItemId
          );
          this.delete_dialog = false;
          this.confirm_delete = false;
        });
      }
    },
  },
  created() {
    axios.get("/hr/rule").then((res) => {
      this.items = res.data.rules;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].rule_type == "percent") {
          this.items[i].rule_type = i18n.t('percent');
        } else if (this.items[i].rule_type == "hours") {
          this.items[i].rule_type = i18n.t("initiate.Day");
        } else if (this.items[i].rule_type == "value") {
          this.items[i].rule_type = i18n.t('constValue');
        }
      }
      this.loading = false;
    });
  },
};
</script>