<template>
  <v-container>
    <v-row justify="center" class="mt-5">
      <v-col md="6">
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          :items="items"
          hide-default-footer
          :loading="loading"
          style="border: 1px solid #ccc; border-radius: 4px; cursor: pointer"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>
                <v-icon color="grey" @click="edit(item)">mdi-pencil</v-icon>
              </td>
              <td>
                <v-icon color="red" @click="deleteitem(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col md="6" class="px-16">
        <v-card outlined flat>
          <v-text-field
            :label="$t('name')"
            class="mx-10 mt-10"
            dense
            outlined
            v-model="name"
          ></v-text-field>
          <br />
          <v-textarea
            clearable
            :label="$t('hr.employee.note')"
            outlined
            class="mx-10"
            v-model="note"
          ></v-textarea>
          <v-btn small color="primary" class="mb-10" @click="save">{{
            $t("save")
          }}</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="editable" max-width="500px">
        <v-card outlined flat>
          <v-text-field
            :label="$t('name')"
            class="mx-10 mt-10"
            dense
            outlined
            v-model="EditName"
          ></v-text-field>
          <br />
          <v-textarea
            clearable
            :label="$t('hr.employee.note')"
            outlined
            class="mx-10"
            v-model="EditNote"
          ></v-textarea>
          <v-btn small color="primary" class="mb-10 mx-10" @click="saveEdit">{{
            $t("save")
          }}</v-btn>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  created() {
    this.initiate();
  },
  data() {
    return {
      headers: [
        { text: "الاسم", value: "name" },
        { text: "ملاحظات", value: "description" },
      ],
      items: [],
      name: "",
      note: "",
      confirm_delete: false,
      delete_dialog: false,
      deleteItemId: null,
      editable: false,
      EditNote: "",
      EditName: "",
      EditId: "",
      loading: true,
    };
  },
  watch: {
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/hr/custody/${this.deleteItemId}`).then((res) => {
          this.items = this.items.filter(
            (item) => item.id !== this.deleteItemId
          );
          if (res.status == 200) {
            this.$Notifications(
              "تم حذف عهدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
          this.delete_dialog = false;
          this.confirm_delete = false;
        });
      }
    },
  },
  methods: {
    edit(item) {
      this.EditName = item.name;
      this.EditNote = item.description;
      this.EditId = item.id;
      this.editable = true;
    },
    saveEdit() {
      axios
        .put(`/hr/custody/${this.EditId}`, {
          name: this.EditName,
          description: this.EditNote,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تم تعديل عهدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.editable = false;
            this.initiate();
          }
        });
    },
    deleteitem(item) {
      this.deleteItemId = item.id;
      this.delete_dialog = true;
    },
    save() {
      axios
        .post("hr/custody", {
          name: this.name,
          description: this.note,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تمت اضافة عهدة جديدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            (this.note = ""), (this.name = "");
            this.initiate();
          }
        });
    },
    initiate() {
      axios.get("/hr/custody").then((res) => {
        this.items = res.data.custodies;
        this.loading = false;
      });
    },
  },
};
</script>